const brandColors = {
  primary: '#314756',
  secondary: '#b2b2b2',
};

const sidebarColors = {
  backgroundColor: '#F1F5F7',
  color: '#314756',
  dotfileLogo: '#3b67f5',
};

const linkColors = {
  color: '#314756',
};

export const tokens = {
  brand: brandColors,
  sidebar: sidebarColors,
  link: linkColors,
};
